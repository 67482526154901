import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Fade } from "react-reveal";
import { NavLink, useLocation } from "react-router-dom";
import Footer from "./Footer";

import "../services.css"; // Import your CSS file

const Distributors = () => {
  const [isVisible, setIsVisible] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      const element = document.getElementById("distributors");
      if (!isVisible && element.getBoundingClientRect().top < window.innerHeight) {
        setIsVisible(true);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isVisible]);

  return (
    <div>
      <section id="distributors">
        
        <Container className="mb-5" style={{ marginTop: "59px" }}>
          <Row>
          <Fade bottom>
          <Col style={{marginLeft:"-30px"}}>
            <br />
            {/* <h3 className={`fs-5 text-center text-white mb-0 ${isVisible ? "fade-in active" : "fade-in"}`}>
              Our Services
            </h3> */}
            <h1 className={`display-6 text-center text-black mb-2 text-bold }`} style={{fontSize:"33px",fontFamily:"sofia-pro-soft-regular,sans-serif"}}>
            Authorized Partners
            </h1>
            <hr className={`w-25 mx-auto text-black}`} />
          </Col>
          </Fade>

          </Row>
          <Row>
            <Col md={4} style={{ marginTop: "40px" }}>
              <Fade bottom>
              <a href="https://www.litepoint.com" target="_blank" rel="noopener noreferrer" className="card-link">
                <div className="card" style={{height:"209px"}}>
                  <div className="card-body text-center">
                  <img
                        src={`${process.env.PUBLIC_URL}/images/litepoint-teradyne-logo.png`}
                        className="rounded-square-dist img-margin"
                        alt="..."
                      />
                    {/* <h6 className="font-services-dist"  style={{fontSize:"22px"}}>LitePoint</h6> */}
                    <p className="card-text lead"  style={{
                        fontSize: "var(--bs-body-font-size)",
                        fontFamily: "var(--bs-body-font-family)",
                        fontWeight: "var(--bs-body-font-weight)",
                        lineHeight: "var(--bs-body-line-height)",
                        color: "var(--bs-body-color)",
                        textAlign: "var(--bs-body-text-align)",
                        marginTop:"30px"
                      }}></p>
                  </div>
                </div>
                </a>
              </Fade>
            </Col>


            <Col md={4} style={{ marginTop: "40px" }}>
              <Fade bottom>
              <a href="https://spirent.com" target="_blank" rel="noopener noreferrer" className="card-link">

                <div className="card" style={{height:"209px"}}>
                  <div className="card-body text-center">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/Logo-Spirent-Blue.jpg`}
                      className="rounded-square-dist img-margin"
                      alt="..."
                    />
                    {/* <h6 className="font-services-dist"style={{fontSize:"22px"}}>Spirent</h6> */}
                    <p className="card-text lead"style={{
                        fontSize: "var(--bs-body-font-size)",
                        fontFamily: "var(--bs-body-font-family)",
                        fontWeight: "var(--bs-body-font-weight)",
                        lineHeight: "var(--bs-body-line-height)",
                        color: "var(--bs-body-color)",
                        textAlign: "var(--bs-body-text-align)",
                        marginTop:"30px"
                      }}></p>
                  </div>
                </div>
                </a>
              </Fade>
            </Col>

            <Col md={4} style={{ marginTop: "40px" }}>
              <Fade bottom>
              <a href="https://vaunix.com" target="_blank" rel="noopener noreferrer" className="card-link">

                <div className="card" style={{height:"209px"}}>
                  <div className="card-body text-center">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/logo.png`}
                      className="rounded-square-dist img-margin"
                      alt="..."
                    />
                    {/* <h6 className="font-services-dist"style={{fontSize:"22px"}}>Vaunix</h6> */}
                    <p className="card-text lead"style={{
                        fontSize: "var(--bs-body-font-size)",
                        fontFamily: "var(--bs-body-font-family)",
                        fontWeight: "var(--bs-body-font-weight)",
                        lineHeight: "var(--bs-body-line-height)",
                        color: "var(--bs-body-color)",
                        textAlign: "var(--bs-body-text-align)",
                        marginTop:"30px"
                      }}></p>
                  </div>
                </div>
              </a>
              </Fade>
            </Col>


          </Row>
        </Container>
      </section>
    </div>
  );
};

export default Distributors;
