import React from "react"; // Import 'React' once at the beginning of the component
import About from "./About";
import Contact from "./Contact";
import Footer from "./Footer";
import Services from "./Services";
import Product from "./Product";
import { NavLink } from "react-router-dom";
import { Card, Container, Row, Col } from "react-bootstrap";
import { Fade } from "react-reveal";
import Navbar from "./navbar"; // Import your Navbar component
import Serv from "./sub_service";
import Distributors from "./distributors";
import Details from "./detail";
import { Link, useHistory, useLocation } from 'react-router-dom';
import "../App.css"; // Import your CSS file
import "../services.css"; // Import your CSS file



// ... rest of your component code


const videoPath = `${process.env.PUBLIC_URL}/video/video.mp4`;

const scrollToCellular = () => {
  const cellularSection = document.getElementById('cellular'); // Get the DOM element of the section

  if (cellularSection) {
    window.scrollTo({
      top: cellularSection.offsetTop,
      behavior: 'smooth', // Smooth scrolling
    });
  }
};

const Home = () => {
  
  return (
    <div>
      <section id="home">
        <div>
          <video autoPlay loop muted id="video-bg">
            <source src={videoPath} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>


        <Container>
          <br />
          <Row justify-content-center>
            <Fade left delay={200}>
              <Row>
                <Col className="text-main" style={{ width: "100%" }}>

                  <h6 className="main-text" style={{ fontSize: "33px", color: "white", textAlign: "center", lineHeight: "61px", marginTop: "52px", fontFamily: "sofia-pro-soft-regular,sans-serif" }}>
                    Welcome to our realm of expertise where we're dedicated to advancing high-speed technology through cutting-edge testing solutions.

                  </h6>


                </Col>

              </Row>

            </Fade>
          </Row>
          <Row>
            <Col md={3}></Col>
            <Col md={9} style={{ marginTop: '36px', width: '96%' }}>
              <div className="d-flex flex-wrap justify-content-left" style={{ justifyContent: "center" }}>
                {/* <?Umar Azeez?> */}
                <Fade left delay={200}>
                  <Link to="/product#connectivity" style={{ textDecoration: 'none' }}>

                    <Card className="slide home1-card" style={{ width: "214px", backgroundColor: "transparent", color: "white", border: "1px solid white", borderRadius: "10px", transition: "background-color 0.3s", }}
                      onMouseEnter={(e) => {
                        e.currentTarget.style.backgroundColor = "#ffffff20"; // Change to the desired background color with opacity
                      }}
                      onMouseLeave={(e) => {
                        e.currentTarget.style.backgroundColor = "transparent";
                      }}>
                      <Card.Body> <p className="home-text" style={{ font: "bold", fontSize: "22px", height: "30px", textAlign: "center" }}>Connectivity </p>
                        <p className="home-text2" style={{ textAlign: "center", fontSize: "15px" }}>(upto WIFI7,  320MHz)</p> </Card.Body>
                    </Card>
                  </Link>
                </Fade>


                <Fade left delay={400}>
                  <Fade left delay={200}>
                    {/* Add NavLink to navigate to Products page */}
                    <Link to="/product#cellular" onClick={scrollToCellular} style={{ textDecoration: 'none' }}>
                      <Card className="slide home2-card" style={{ width: '214px', backgroundColor: 'transparent', color: 'white', border: '1px solid white', borderRadius: '10px', transition: 'background-color 0.3s' }}
                      onMouseEnter={(e) => {
                        e.currentTarget.style.backgroundColor = "#ffffff20"; // Change to the desired background color with opacity
                      }}
                      onMouseLeave={(e) => {
                        e.currentTarget.style.backgroundColor = "transparent";
                      }}>
                        <Card.Body>
                          <p className="home-text" style={{ font: 'bold', fontSize: '22px', height: '30px', textAlign: 'center' }}>Cellular</p>
                          <p className="home-text2" style={{ textAlign: 'center', fontSize: '15px' }}>(5G Sub 6GHz, mmWave)</p>
                        </Card.Body>
                      </Card>
                    </Link>

                  </Fade>
                </Fade>
 


                <Fade left delay={600}>
                  <Link to="/product#chambers" style={{ textDecoration: 'none' }}>

                    <Card className="slide home3-card" style={{ width: "214px", height: "118px", backgroundColor: "transparent", color: "white", border: "1px solid white", borderRadius: "10px", transition: "background-color 0.3s", }}
                      onMouseEnter={(e) => {
                        e.currentTarget.style.backgroundColor = "#ffffff20"; // Change to the desired background color with opacity
                      }}
                      onMouseLeave={(e) => {
                        e.currentTarget.style.backgroundColor = "transparent";
                      }}>
                      <Card.Body> <p className="home-text" style={{ font: "bold", fontSize: "22px", textAlign: "center" }}>OTA Performance Test beds</p>
                      </Card.Body>
                    </Card>
                  </Link>
                </Fade>

                <Fade left delay={800}>
                  <Link to="/product#vaunix" style={{ textDecoration: 'none' }}>

                    <Card className="slide homes-card" style={{ width: "214px", height: "116px", backgroundColor: "transparent", color: "white", border: "1px solid white", borderRadius: "10px", transition: "background-color 0.3s", }}
                      onMouseEnter={(e) => {
                        e.currentTarget.style.backgroundColor = "#ffffff20"; // Change to the desired background color with opacity
                      }}
                      onMouseLeave={(e) => {
                        e.currentTarget.style.backgroundColor = "transparent";
                      }}>
                      <Card.Body> <p className="home-text" style={{ font: "bold", fontSize: "22px", textAlign: "center", marginTop: "-5px" }}> Programmable Attenuators & Signal Generaters </p>
                      </Card.Body>
                    </Card>
                  </Link>
                </Fade>

              </div>

              <div className="container my-3 py-3">
                <div className="buttons-home btton d-flex justify-content-" style={{ justifyContent: "center" }}>
                  <Fade left delay={1200}>
                    <NavLink to="/product" className="hm-text btn btn-outline-light rounded-pill px-4 py-2">

                      Products
                    </NavLink>

                  </Fade>
                  <Fade left delay={1400}>
                    <NavLink
                      to="/sub_service"
                      className="hm-text btn btn-outline-light rounded-pill px-4 py-2"
                      style={{ marginLeft: "12%" }}
                    >
                      Services
                    </NavLink>
                  </Fade>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <Services />
      <Distributors />
      <About />
      <Details />

      {/* <Contact /> */}
      <Footer />
    </div>
  );
};

export default Home;