import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import Footer from "./Footer";
import Button from 'react-bootstrap/Button';

const Signin = () => {
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');
  const [otpValidation, setOtpValidation] = useState('');
  const [backendOtp, setBackendOtp] = useState(''); // State to store the OTP from the backend

  const handleGenerateOTP = () => {
    // Send a request to the backend to generate and send OTP
    fetch('http://localhost:3002/btn-generate-otp', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ senderEmail: email }),
    })
      .then(response => response.json())
      .then(data => {
        console.log(data);
        setBackendOtp(data.otp); // Assuming the OTP is sent back in the response
        setOtpValidation(''); // Clear previous validation
      })
      .catch(error => {
        console.error('Error:', error);
        setOtpValidation(''); // Clear previous validation
      });
  };
  const handleSubmit = () => {
    // Validate entered OTP with the OTP from the backend
    
    if (otp === backendOtp) {
      setOtpValidation('valid');
      // You can redirect to the login page or perform other actions here
      alert('Correct OTP.');
    } else {
      setOtpValidation('invalid');
      // You can show a popup or alert here for incorrect OTP
      alert('Incorrect OTP. Please try again.');
    }
  };
    return (
        <div>
            <div className="container mb-5">
                <div className="row mt-5 shadow justify-content-end">
                    {/* <div className="col-md-5 d-flex flex-column align-items-center form  order-2">
                        <h1 className="display-4 mt-5">
                            Welcome User
                        </h1>
                        <p className="lead mb-0">
                            Already Signed in ? <br/> please Login below
                        </p>
                        <h5 className="mt-5 mb-5">---OR---</h5>
                        <NavLink to="/login" className="btn btn-outline-light rounded-pill pb-2 w-50">LogIn</NavLink>
                    </div> */}
                        <h1 className="display-6 fw-bolder mb-5">SIGNIN</h1>
                        <form>
                        <div className="mb-3">
        <label htmlFor="exampleInputEmail1" className="form-label">Email address</label>
        <input
          type="email"
          className="form-control"
          placeholder="name@example.com"
          id="exampleInputEmail1"
          aria-describedby="emailHelp"
          onChange={(e) => setEmail(e.target.value)}
        />
        <div id="emailHelp" className="form-text">We'll never share your email with anyone else.</div>
        <Button variant="primary" className="btn-generate-otp" onClick={handleGenerateOTP}>
          Generate OTP
        </Button>
      </div>

      <div className="mb-3">
        <label htmlFor="exampleInputPassword1" className="form-label">OTP</label>
        <input
          type="text"
          className={`form-control ${otpValidation === 'valid' ? 'border-success' : (otpValidation === 'invalid' ? 'border-danger' : '')}`}
          placeholder="Please enter your OTP"
          id="exampleInputPassword1"
          onChange={(e) => setOtp(e.target.value)}
        />
      </div>

      <div className="mb-3">
        <NavLink to="/login" className={`btn btn-outline-light rounded-pill pb-2 w-50 ${otpValidation === 'valid' ? 'disabled' : ''}`}>
          <button type="submit" className="btn btn-primary" onClick={handleSubmit} disabled={otpValidation === 'valid'}>Submit</button>
        </NavLink>
      </div>

                        </form>
                    
                </div>
            </div>
            <Footer/>
        </div>
    );
}
export default Signin