import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Fade } from "react-reveal";
import { NavLink } from "react-router-dom";
import { Carousel } from "react-bootstrap"; // Import the Carousel component
import "../App.css"; // Import your CSS file


import "../services.css"; // Import your CSS file

const OurWork = () => {
  useEffect(() => {
    // Scroll to the top when the component mounts
    window.scrollTo(0, 0);
  }, []);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const element = document.getElementById("service");
      if (!isVisible && element.getBoundingClientRect().top < window.innerHeight) {
        setIsVisible(true);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isVisible]);

  const images_instru = [
    "/images/image005.jpg",
    "/images/cropped.jpeg",
    "/images/vnx3.jpg",
    "/images/thumbnail_image004.jpg",
  ];


  const images_calibration = [
    "/images/electro-technical-calibration-service-2.jpg",
    "/images/depositphotos_517028010-stock-photo-power-supplies-electronic-measuring-devices.jpg",
    "/images/calibrt.jpg",
  ];

  const images_consulting = [
    "/images/expertise.jpg",
    "/images/consulting_2 (1).jpg",
    "/images/cservice3.jpg",
  ];

  const images_training = [
    "/images/istockphoto-1353769234-170667a (1).jpg",
    "/images/training1.png",
    "/images/training.png",
  ];

  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % images_instru.length);
    }, 3200);

    return () => clearInterval(interval);
  }, []);

  const [currentSlideCalibration, setCurrentSlideCalibration] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlideCalibration(
        (prevSlide) => (prevSlide + 1) % images_calibration.length
      );
    }, 3200);

    return () => clearInterval(interval);
  }, []);

  const [currentSlideConsulting, setCurrentSlideConsulting] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlideConsulting(
        (prevSlide) => (prevSlide + 1) % images_consulting.length
      );
    }, 3200);

    return () => clearInterval(interval);
  }, []);

  const [currentSlideTraining, setCurrentSlideTraining] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlideTraining(
        (prevSlide) => (prevSlide + 1) % images_training.length
      );
    }, 3200);

    return () => clearInterval(interval);
  }, []);
  
  return (
    <div>
    <section id="service">
  <Container className="service-sc mb-5" style={{ marginTop: "152px" }}>
  <Row>
        
        <Col style={{}}>
            <br />
            {/* <h3 className={`fs-5 text-center text-white mb-0 ${isVisible ? "fade-in active" : "fade-in"}`}>
              Our Services
            </h3> */}
            <h1 className={`service-sc1 display-6 text-center text-white mb-2 text-bold ${isVisible ? "fade-in active" : "fade-in"}`} style={{ color:"white",fontSize:"33px",fontFamily:"sofia-pro-soft-regular,sans-serif" }}>
            Our Comprehensive Services
            </h1>
            <hr className={`service-sc2 w-25 mx-auto text-white ${isVisible ? "fade-in active" : "fade-in"}`} />
          </Col>
          
        </Row>
  <Row>


  <Col md={3}>
              <Fade left>
                <div className="card">
                  <Carousel
                    activeIndex={currentSlide}
                    onSelect={(index) => setCurrentSlide(index)}
                    interval={null} // To prevent automatic sliding
                  >
                    {images_instru.map((images_instru, index) => (
                      <Carousel.Item key={index}>
                        <img
                          src={process.env.PUBLIC_URL + images_instru}
                          alt={`Image ${index + 1}`}
                          className="card-image"
                          style={{ width: "105%", marginLeft: "-7px" }}
                        />
                      </Carousel.Item>
                    ))}
                  </Carousel>
                  <div className="card-content">
                    <h6 className="font-services">
                      <p
                        style={{
                          fontSize: "22px",
                          fontFamily: "var(--bs-body-font-family)",
                        }}
                      >
                        Instrumentation
                      </p>
                      <p
                        className="card-text lead"
                        style={{
                          fontSize: "var(--bs-body-font-size)",
                          fontFamily: "var(--bs-body-font-family)",
                          fontWeight: "var(--bs-body-font-weight)",
                          lineHeight: "var(--bs-body-line-height)",
                          color: "var(--bs-body-color)",
                          textAlign: "var(--bs-body-text-align)",
                          marginTop: "-10px",
                        }}
                      >
                        From signal generators to spectrum analyzers, wireless testbeds, & digital programmable attenuators
                      </p>
                    </h6>
                  </div>
                </div>
              </Fade>
            </Col>

            <Col md={3}>
              <Fade left>
                <div className="card">
                  <Carousel
                    activeIndex={currentSlideCalibration}
                    onSelect={(index) => setCurrentSlideCalibration(index)}
                    interval={null}
                  >
                    {images_calibration.map((image, index) => (
                      <Carousel.Item key={index}>
                        <img
                          src={process.env.PUBLIC_URL + image}
                          alt={`Image ${index + 1}`}
                          className="card-image"
                          style={{ width: "105%", marginLeft: "-7px" }}
                        />
                      </Carousel.Item>
                    ))}
                  </Carousel>
                  <div className="card-content">
                    <h6 className="font-services">
                      <p
                        style={{
                          fontSize: "22px",
                          fontFamily: "var(--bs-body-font-family)",
                        }}
                      >
                        Calibration Services
                      </p>
                      <p
                        className="card-text lead"
                        style={{
                          fontSize: "var(--bs-body-font-size)",
                          fontFamily: "var(--bs-body-font-family)",
                          fontWeight: "var(--bs-body-font-weight)",
                          lineHeight: "var(--bs-body-line-height)",
                          color: "var(--bs-body-color)",
                          textAlign: "var(--bs-body-text-align)",
                          marginTop: "-10px",
                        }}
                      >
                        Ensure the accuracy of your measurement equipment with our accredited calibration services.
                      </p>
                    </h6>
                  </div>
                </div>
              </Fade>
            </Col>

            <Col md={3}>
              <Fade right>
                <div className="card">
                  <Carousel
                    activeIndex={currentSlideConsulting}
                    onSelect={(index) => setCurrentSlideConsulting(index)}
                    interval={null}
                  >
                    {images_consulting.map((image, index) => (
                      <Carousel.Item key={index}>
                        <img
                          src={process.env.PUBLIC_URL + image}
                          alt={`Image ${index + 1}`}
                          className="card-image"
                          style={{ width: "105%", marginLeft: "-7px" }}
                        />
                      </Carousel.Item>
                    ))}
                  </Carousel>
                  <div className="card-content">
                    <h6 className="font-services">
                      <p
                        style={{
                          fontSize: "22px",
                          fontFamily: "var(--bs-body-font-family)",
                        }}
                      >
                        Consulting Expertise
                      </p>
                      <p
                        className="card-text lead"
                        style={{
                          fontSize: "var(--bs-body-font-size)",
                          fontFamily: "var(--bs-body-font-family)",
                          fontWeight: "var(--bs-body-font-weight)",
                          lineHeight: "var(--bs-body-line-height)",
                          color: "var(--bs-body-color)",
                          textAlign: "var(--bs-body-text-align)",
                          marginTop: "-10px",
                        }}
                      >
                        Elevate your efficiency through our industry-tailored consulting and optimized processes.
                      </p>
                    </h6>
                  </div>
                </div>
              </Fade>
            </Col>

            <Col md={3}>
              <Fade right>
                <div className="card">
                  <Carousel
                    activeIndex={currentSlideTraining}
                    onSelect={(index) => setCurrentSlideTraining(index)}
                    interval={null}
                  >
                    {images_training.map((image, index) => (
                      <Carousel.Item key={index}>
                        <img
                          src={process.env.PUBLIC_URL + image}
                          alt={`Image ${index + 1}`}
                          className="card-image"
                          style={{ width: "105%", marginLeft: "-7px" }}
                        />
                      </Carousel.Item>
                    ))}
                  </Carousel>
                  <div className="card-content">
                    <h6 className="font-services">
                      <p
                        style={{
                          fontSize: "22px",
                          fontFamily: "var(--bs-body-font-family)",
                        }}
                      >
                        Training and Education
                      </p>
                      <p
                        className="card-text lead"
                        style={{
                          fontSize: "var(--bs-body-font-size)",
                          fontFamily: "var(--bs-body-font-family)",
                          fontWeight: "var(--bs-body-font-weight)",
                          lineHeight: "var(--bs-body-line-height)",
                          color: "var(--bs-body-color)",
                          textAlign: "var(--bs-body-text-align)",
                          marginTop: "-10px",
                        }}
                      >
                        Empower your team with our comprehensive training programs led by industry experts.
                      </p>
                    </h6>
                  </div>
                </div>
              </Fade>
            </Col>
    
{/* 

    <Col md={3}>
      <Fade left>
        <div className="card" >
        <img
          src={process.env.PUBLIC_URL + "/images/calibrat.gif"}
          alt="Card 1"
          className="card-image"
          style={{width: "123%"}}
        />
          <div className="card-content">
          <h6 className="font-services">
          <p style={{ fontSize:"22px", fontFamily: "var(--bs-body-font-family)" }}>Calibration Services</p>
          <p className="card-text lead" style={{
  fontSize: "var(--bs-body-font-size)",
  fontFamily: "var(--bs-body-font-family)",
  fontWeight: "var(--bs-body-font-weight)",
  lineHeight: "var(--bs-body-line-height)",
  color: "var(--bs-body-color)",
  textAlign: "var(--bs-body-text-align)",
  marginTop:"-10px"
}}>
             Ensure the accuracy of your measurement equipment with our accredited calibration services.</p>
          </h6>

          </div>
        </div>
      </Fade>
    </Col>

    <Col md={3}>
      <Fade right>
        <div className="card" >
        <img
          src={process.env.PUBLIC_URL + "/images/consults.gif"}
          alt="Card 1"
          className="card-image"
          style={{width: "150%"}}


        />
          <div className="card-content">
            
          <h6 className="font-services">
          <p style={{ fontSize:"22px", fontFamily: "var(--bs-body-font-family)" }}>Consulting Expertise</p>

          <p className="card-text lead" style={{
  fontSize: "var(--bs-body-font-size)",
  fontFamily: "var(--bs-body-font-family)",
  fontWeight: "var(--bs-body-font-weight)",
  lineHeight: "var(--bs-body-line-height)",
  color: "var(--bs-body-color)",
  textAlign: "var(--bs-body-text-align)",
  marginTop:"-10px"
}}>
  Elevate your efficiency through our industry-tailored consulting and optimized processes
</p>

                        </h6>
          </div>
        </div>
      </Fade>
    </Col>

    <Col md={3}>
      <Fade right>
        <div className="card" >
        <img
          src={process.env.PUBLIC_URL + "/images/trains.gif"}
          alt="Card 1"
          className="card-image"
          style={{width: "130%",marginLeft:'-64px'}}

          
        />
          <div className="card-content">
          <h6 className="font-services">
          <p style={{fontSize:"22px", fontFamily: "var(--bs-body-font-family)" }}>Training and Education</p>
                        
          <p className="card-text lead" style={{
  fontSize: "var(--bs-body-font-size)",
  fontFamily: "var(--bs-body-font-family)",
  fontWeight: "var(--bs-body-font-weight)",
  lineHeight: "var(--bs-body-line-height)",
  color: "var(--bs-body-color)",
  textAlign: "var(--bs-body-text-align)",
  marginTop:"-10px"
}}>                        Empower your team with our comprehensive training programs led by industry experts. </p>                       </h6>
                        
          </div>
        </div>
      </Fade>
    </Col> */}
    <div className="d-flex flex-wrap justify-content-left" style={{justifyContent:"center"}}>
    <NavLink to="/service" style={{ textDecoration: "none" }}>
          <Button
            variant="primary"
            style={{
              // marginLeft: "477px",
              marginBottom: "-67px",
              backgroundColor: "rgb(21, 16, 25)",
              color: "lightblue",
              transition: "box-shadow 0.3s",
            }}
            className="btn btn-blue me-4 rounded-pill px-4 py-2"
            onMouseEnter={(e) => {
              e.currentTarget.style.boxShadow = "0 0 10px rgba(0, 0, 0, 0.5)";
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.boxShadow = "none";
            }}
          >
            Know More
          </Button>
        </NavLink>
        </div>
  </Row>
</Container>
</section>

</div>
);
};


export default OurWork;