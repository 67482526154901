import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Fade } from "react-reveal"; // Import the Fade component from react-reveal
import { NavLink } from "react-router-dom";


const Footer = () => {
  return (
    <div>
      <section id="footer" className="bg-dark text-light">
        <Container className="py-5">
          <Row>
            <Col md={4}>
              <Fade down> {/* Add the Fade component with the "down" animation */}
                <div className="lead">
                  <h5>Address</h5>
                  <hr className="bg-light" />
                  <p>
                    Office:<br />
                    Premier Measurement Solutions Pvt Ltd.
                    #847, 2nd Floor, 'A' Block,
                    Sahakaranagar
                    Bangalore - 560092
                  </p>
                  <p>
                    Contact:<br />
                    Phone: 080-4164 3659<br />
                    Email: info@pmeasure.com
                  </p>
                </div>
              </Fade>
            </Col>
            <Col md={4}>
              <Fade down> {/* Add the Fade component with the "down" animation */}
                <div className="lead">
                  <h5>Important Links</h5>
                  <hr className="bg-light" />
                  <ul className="list-unstyled">
                    <li>
                    <NavLink to="/" className="nav-link">
                  Home
                </NavLink>
                    </li>
                    <li>
                    <NavLink to="/Product" className="nav-link">
                  Products
                </NavLink>
                    </li>
                    <li>
                    <NavLink to="/sub_service" className="nav-link">
                  Services
                </NavLink>
                    </li>

                    <li>
                    <NavLink to="/about" className="nav-link">
                  About Us
                </NavLink>
                    </li>
                    <li>
                    <NavLink to="/distributors" className="nav-link">
                  Our Partners
                </NavLink>
                    </li>
                    <li>
                    <NavLink to="/contact" className="nav-link">
                  Contact
                </NavLink>
                    </li>
                    {/* <li>
                    <a href="/signin" className="text-light text-decoration-none">
                        Admin
                    </a>
                    </li> */}

                  </ul>
                </div>
              </Fade>
            </Col>
            <Col md={4}>
              <Fade down> {/* Add the Fade component with the "down" animation */}
                <div className="lead">
                  <h5>Social Media</h5>
                  <hr className="bg-light" />
                  <a href="https://in.linkedin.com/company/premier-measurement-solutions-private-limited" className="text-light text-decoration-none">
                    <img src={`${process.env.PUBLIC_URL}/images/linkedin-img.png`} alt="LinkedIn" className="mr-2" style={{width:"50px"}} />
                    <span style={{ marginLeft: "7px" }}>LinkedIn</span>
                  </a>

                </div>
              </Fade>
            </Col>
          </Row>
          <Row>
            <Col>
              <Fade down> {/* Add the Fade component with the "down" animation */}
                <hr className="bg-light" />
                <div className="text-center">
                  <p>&copy; COPYRIGHT@2023 Premier Measurement Solutions Pvt Ltd.<br></br>All rights reserved.</p>
                </div>
              </Fade>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default Footer;
