// import React, { useState, useEffect } from "react";
// import { NavLink } from "react-router-dom";
// import "../services.css"; // Import your CSS file
// import { Container, Row, Col, Button } from "react-bootstrap";



// const Navbar = () => {
//   const [showNavbar, setShowNavbar] = useState(true);

//   useEffect(() => {
//     const handleResize = () => {
//       if (window.innerWidth <= 992) {
//         setShowNavbar(false);
//       } else {
//         setShowNavbar(true);
//       }
//     };

//     handleResize();

//     window.addEventListener("resize", handleResize);

//     return () => {
//       window.removeEventListener("resize", handleResize);
//     };
//   }, []);

//   const handleNavLinkClick = () => {
//     // When any link is clicked, set "Home" link to be inactive
//     document.getElementById("home-link")?.classList.remove("active");
//   };

//   return (
//     <div>
//       {showNavbar && (
//         <section
//           id="navbar"
//           style={{ backgroundColor: "transparent", height: "63px", marginTop: "-5px" }}
//         >
//           <div className="navbar navbar-expand-lg py-3 " style={{ borderRadius: "50px" }}>
//             <row className="container" style={{ height: "31px" }}>
//               <Col>
//                 <div className="logo" style={{ marginLeft: "-59px" }}>
//                   <img
//                     src={`${process.env.PUBLIC_URL}/images/PMS-logo.png`}
//                     alt=""
//                     width="118px"
//                     height="50px"
//                     style={{ width: "177px", height: "54px" }}
//                   />
//                 </div>
//               </Col>
//               <Col>
//               <ul className="navbar-nav" style={{ marginLeft: "-99px" }}>
//               <li className="nav-item home-link" style={{ fontFamily: "var(--bs-body-font-family)" }}>
//                         <NavLink to="/" className="nav-link">
//                           Home
//                         </NavLink>
//                       </li>
//                 <li className="nav-item" style={{ fontFamily: "var(--bs-body-font-family)" }}>
//                   <NavLink to="/Product" className="nav-link">
//                     Products
//                   </NavLink>
//                 </li>
//                 <li className="nav-item" style={{ fontFamily: "var(--bs-body-font-family)" }}>
//                   <NavLink to="/sub_service" className="nav-link">
//                     Services
//                   </NavLink>
//                 </li>
//                 <li className="nav-item" style={{ fontFamily: "var(--bs-body-font-family)" }}>
//                   <NavLink to="/about" className="nav-link">
//                     About Us
//                   </NavLink>
//                 </li>
//                 <li className="nav-item" style={{ fontFamily: "var(--bs-body-font-family)" }}>
//                   <NavLink to="/distributors" className="nav-link">
//                     Our Partners
//                   </NavLink>
//                 </li>
//                 <li className="nav-item" style={{ fontFamily: "var(--bs-body-font-family)" }}>
//                   <NavLink to="/contact" className="nav-link">
//                     Contact
//                   </NavLink>
//                 </li>
//               </ul>
//               </Col>
//             </row>
//           </div>
//         </section>
//       )}
//     </div>
//   );
// };

// export default Navbar;

import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { Container, Navbar, Nav, NavDropdown } from "react-bootstrap";
import "../services.css"; // Import your CSS file

const NavbarComponent = () => {
  const [showNavbar, setShowNavbar] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 992) {
        setShowNavbar(false);
      } else {
        setShowNavbar(true);
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleNavLinkClick = () => {
    // When any link is clicked, set "Home" link to be inactive
    document.getElementById("home-link")?.classList.remove("active");
  };

  return (
    <div>
      <Navbar
        expand="lg"
        bg="light" // You can change the background color here
        variant="light"
      >
        <Container>
          <Navbar.Brand>
          <NavLink to="/" className="nav-link">
            <img
              src={`${process.env.PUBLIC_URL}/images/PMS-logo.png`}
              alt=""
              width="118px"
              height="54px"
              style={{ width: "177px", height: "56px" }}
            />
            </NavLink>
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="basic-navbar-nav" />

          <Navbar.Collapse style={{justifyContent:"end"}}>
            <Nav className="ml-auto">
              <li className="nav-item home-link" style={{ fontFamily: "var(--bs-body-font-family)" }}>
                <NavLink to="/" className="nav-link">
                  Home
                </NavLink>
              </li>
              <li className="nav-item" style={{ fontFamily: "var(--bs-body-font-family)" }}>
                <NavLink to="/Product" className="nav-link">
                  Products
                </NavLink>
              </li>
              <li className="nav-item" style={{ fontFamily: "var(--bs-body-font-family)" }}>
                <NavLink to="/sub_service" className="nav-link">
                  Services
                </NavLink>
              </li>
              <li className="nav-item" style={{ fontFamily: "var(--bs-body-font-family)" }}>
                <NavLink to="/about" className="nav-link">
                  About Us
                </NavLink>
              </li>
              <li className="nav-item" style={{ fontFamily: "var(--bs-body-font-family)" }}>
                <NavLink to="/distributors" className="nav-link">
                  Our Partners
                </NavLink>
              </li>
              <li className="nav-item" style={{ fontFamily: "var(--bs-body-font-family)" }}>
                <NavLink to="/contact" className="nav-link">
                  Contact
                </NavLink>
              </li>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default NavbarComponent;
