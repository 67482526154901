import React, { useEffect, useLayoutEffect } from "react";
import Footer from "./Footer";
import { Fade, Slide } from "react-reveal";
import "../services.css"; // Import your CSS file

const ServiceDetail = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <section id="services-detail">
        <div className="container my-5 py-5">
          {/* Animation for "Our Services" heading */}
          <Fade bottom>
          {/* <h3 className="fs-5 text-center mb-0" style={{ marginTop: "-74px" }}>Our Services</h3> */}
          </Fade>

          {/* Animation for "Our High Quality Services" heading */}
          <Fade bottom>
            <h1 className="display-6 text-center mb-4">
              <h1 style={{ color:"Black",fontSize:"33px",fontFamily:"sofia-pro-soft-regular,sans-serif" }}>Our High Quality Services</h1>
            </h1>
          </Fade>

          {/* Animation for the first row */}
          <Slide left>
            <div className="row shadow mt-5 p-5 mb-5">
              <div className="serv-det2 col-md-3">
                <img
                        src={`${process.env.PUBLIC_URL}/images/image005.jpg`}
                        alt="About"
                  className="card-image-serv"
                  style={{ maxWidth: "100%" }}
                />
              </div>
              <div className="serv-det col-9">
                <h6>Automated test solutions and software development for R&D and Manufacturing in the following areas:</h6>
                <hr />
                <ul>
              <li>Connectivity: WIFI 802.11be/ax/ac/n/b/g/a, MU-MIMO, MIMO, SISO, Bluetooth, UWB, Zigbee, GPS, and other positioning technologies.</li>
              <li>Cellular: 5G O-RAN, 5G FR1/FR2, LTE Advanced, LTE, UMTS, WCDMA, GSM, NFC.</li>
              <li>Devices: Access points, Fixed Wireless Access points, Smartphones (2G/3G/4G/5G), Tablets, Data cards, Wi-Fi/Cellular modules, SIP.</li>
            </ul>

              </div>
            </div>
          </Slide>



          {/* Animation for the third row */}
          <Slide left>
            <div className="row shadow mt-5 p-5 mb-5">
              <div className="serv-det2 col-md-3">
                <img
                        src={`${process.env.PUBLIC_URL}/images/thumbnail_image004.jpg`}
                        alt="About"
                  className="card-image-serv"
                  style={{ maxWidth: "100%" }}
                />
              </div>
              <div className="serv-det col-9">
                <h6>Wireless testbeds for R&D, QA and production testing of 802.11 devices upto 11be:</h6>
                <hr />
                <ul>
                  <li>Anechoic chamber or shielded chamber for SISO/MIMO OTA, throughput, mesh testing, high-speed roaming, and other wireless tests.</li>
                  <li>Turnkey Solution including a programmable Turntable and Multipath Emulator (MPE).</li>
                </ul>

              </div>
            </div>
          </Slide>


                    {/* Animation for the second row */}
                    <Slide right>
            <div className="row shadow mt-5 p-5 mb-5">
              <div className="serv-det2 col-md-3">
                <img
                        src={`${process.env.PUBLIC_URL}/images/digital_attenuator_pair-2.jpg`}
                        alt="About"
                  className="card-image-serv"
                                    style={{ maxWidth: "100%" }}

                />
              </div>
              <div className="serv-det col-9 ">
                <h6> Low cost and high-performance USB compatible Signal generators, Programmable Digital Attenuators, RF Switches, Phase Shifters and USB Hubs:</h6>
                <hr />
                <ul>
                    <li>Automated Test equipment, Portable LO source, Engineering and Production test Laboratories.</li>
                    <li>WiMax, Wi-Fi, Cellular, and 3G fading simulators.</li>
                    <li>Beam forming, Signal Cancellation, Phase modulators, MIMO test platform for LTE and WiFi.</li>
                    <li>We offer a range of attenuator matrices for unique wireless handover testing requirements.</li>
                  </ul>

              </div>
            </div>
          </Slide>

          {/* Animation for the fourth row */}
          <Slide right>
          <div className="row shadow mt-5 p-5 mb-5">
            <div className="serv-det2 col-md-3">
              <img
                src={`${process.env.PUBLIC_URL}/images/testauto.jpg`}
                alt="About"
                className="card-image-serv"
                                  style={{ maxWidth: "100%" }}

              />
            </div>
            <div className="serv-det col-9" style={{ overflowY: 'auto', maxHeight: '150px' }}>
              <h6>Test Automation:</h6>
              <hr />
              <ul>
                  <li>We create tailor-made software automation solutions, aligned with your specific needs and goals. Our automation services grow with your business, offering flexibility and efficiency for companies of all sizes.</li>
                  <li>Automated test systems we supply, and the associated software are all designed to be deployed in the toughest environment - the manufacturing floor. Production tests are optimized and customized to ensure the fastest test time, lower cost of ownership, and maximum efficiency.</li>
                  <li>Simple to deploy: Easy to rack or stack. Direct cable connections from tester to fixture. Automatic configuration. Simple fixture calibration.</li>
                  <li>Easy to use: Our purpose-built test systems are intuitive to control, even for new operators. Software utilizes an intuitive graphical user interface that provides results in a straightforward, visual format.</li>
                  <li>Compact multi-tasker: Single-box solutions require minimal factory floor space and can concurrently test multiple standards with a single insertion.</li>
                </ul>

            </div>
          </div>
          </Slide>
          <Slide left>
            <div className="row shadow mt-5 p-5 mb-5">
              <div className="serv-det2 col-md-3">
                <img
                        src={`${process.env.PUBLIC_URL}/images/electro-technical-calibration-service-2.jpg`}
                        alt="About"
                  className="card-image-serv"
                  style={{ maxWidth: "100%" }}
                  
                />
              </div>
              <div className="serv-det col-9" style={{ overflowY: 'auto', maxHeight: '150px' }}>
                <h6>Repair and Calibration:</h6>
                <hr />
                <ul>
                  <li>Premier Measurement Solutions is committed to providing on-time repair and calibration services with quick turnaround times.</li>
                  <li>Factory-recommended upgrades to software are performed each time a service is carried out to ensure that the health of your instrument is always excellent.</li>
                  <li>We have a variety of services to suit your business needs. We have standard service agreements (AMC’s) or a one-time repair solution tailored to your immediate requirements.</li>
                  <li>Please contact us with the required information, and we will get back to you promptly.</li>
                </ul>



              </div>
            </div>
          </Slide>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default ServiceDetail;

// import React, { useEffect, useLayoutEffect } from "react";
// import Footer from "./Footer";
// import { Fade, Slide } from "react-reveal";

// const ServiceDetail = () => {
//   useLayoutEffect(() => {
//     window.scrollTo(0, 0);
//   }, []);

//   return (
//     <div>
//       <section id="services-detail">
//         <div className="container my-5 py-5">
//           <Fade bottom>
//             <h1 className="display-6 text-center mb-4">
//               <h1 style={{ color: "Black", fontSize: "33px", fontFamily: "sofia-pro-soft-regular,sans-serif" }}>Our High Quality Services</h1>
//             </h1>
//           </Fade>

//           <Slide left>
//             <div className="row shadow mt-5 p-5 mb-5">
//               <div className="col-md-3">
//                 <img
//                   src={`${process.env.PUBLIC_URL}/images/image005.jpg`}
//                   alt="About"
//                   className="card-image-serv"
//                   style={{ maxWidth: "100%" }}
//                 />
//               </div>
//               <div className="col-md-9">
//                 <div style={{ maxHeight: "300px", overflowY: "auto" }}>
//                   <h6>Automated test solutions and software development for R&D and Manufacturing in the following areas:</h6>
//                   <hr />
//                   <ul>
//                     <li>Connectivity: WIFI 802.11be/ax/ac/n/b/g/a, MU-MIMO, MIMO, SISO, Bluetooth, UWB, Zigbee, GPS, and other positioning technologies.</li>
//                     <li>Cellular: 5G O-RAN, 5G FR1/FR2, LTE Advanced, LTE, UMTS, WCDMA, GSM, NFC.</li>
//                     <li>Devices: Access points, Fixed Wireless Access points, Smartphones (2G/3G/4G/5G), Tablets, Data cards, Wi-Fi/Cellular modules, SIP.</li>
//                   </ul>
//                 </div>
//               </div>
//             </div>
//           </Slide>

//           {/* Add similar style for other rows */}
//           {/* ... */}

//         </div>
//       </section>
//       <Footer />
//     </div>
//   );
// };

// export default ServiceDetail;
