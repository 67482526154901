import React from "react";
import { NavLink } from "react-router-dom";
import Footer from "./Footer";
const Carrier =() => {
    return(
    <div>
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <h1 className="display-4 text-center">
                        Hello
                    </h1>
                    <p className="lead text-center">
                        Thank you for Your Interst in Pmeasure Solutions
                    </p>
                    <p className="lead text-center">
                        Bellow are some of the latest openings in Pmeasure Solutions
                    </p>
                </div>
            </div>
        </div>
        <Footer/>
    </div>
    );
}
export default Carrier