
// App.jsx
import './App.css';
import About from './components/About';
import Contact from './components/Contact';
import Home from './components/Home';
import Navbar from './components/navbar';
import Product from './components/Product';
import Services from './components/Services';
import { Route, Switch } from 'react-router-dom';
import Login from './components/Login';
import Signin from './components/Signin';
import Carrier from './components/Carrier';
import ServicesDetails from './components/ServicesDetail';
import Distributors from './components/distributors'; // Use lowercase 'd'
import Serv from './components/sub_service';

function App() {
  return (
    <>
      <Navbar />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/sub_service" component={Serv} />
        <Route exact path="/about" component={About} />
        <Route exact path="/product" component={Product} />
        <Route exact path="/contact" component={Contact} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/signin" component={Signin} />
        <Route exact path="/carrier" component={Carrier} />
        <Route exact path="/service" component={ServicesDetails} />
        <Route exact path="/distributors" component={Distributors} /> {/* Use lowercase 'd' */}
      </Switch>
    </>
  );
}

export default App;
