import React, { useEffect, useState } from "react";
import Footer from "./Footer";
import { NavLink } from "react-router-dom";
import { Fade } from "react-reveal";

const About = () => {
  useEffect(() => {
    // Scroll to the top when the component mounts
    window.scrollTo(0, 0);
  }, []);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const element = document.getElementById("about");
      if (!isVisible && element.getBoundingClientRect().top < window.innerHeight) {
        setIsVisible(true);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isVisible]);

  const backgroundImageStyle = {
    backgroundImage: `url(${process.env.PUBLIC_URL}/images/AboutUs.png)`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    minHeight: "50vh",
  };

  const verticalLineStyle = {
    borderLeft: "1px solid white", // Adjust the color and style as needed
    height: "100%", // Make the line stretch to the full height
  };

  return (
    <div>
      <section id="about" style={backgroundImageStyle}>
        <br />
        <div className="row">
          <div className="col-md-6 mx-auto">
            <Fade left={isVisible}> {/* Add the Fade component */}
              {/* Left Column Content */}
            </Fade>
          </div>

          {/* Vertical Line */}
          <div className="vertical-line mx-4" style={verticalLineStyle}></div>

          <div className="col-md-6 mx-auto">
          <div className="card text-white bg-dark p-4" style={{ width: "100%" }}>
              <Fade right={isVisible}> {/* Add the Fade component */}
                <h3 className={`${isVisible ? "fade-in active" : "fade-in"}`} style={{fontSize:"33px"}}>About Us</h3>
                <h1 className={`display-6 mb-2 ${isVisible ? "fade-in active" : "fade-in"}`}style={{fontSize:"22px",fontFamily:"sans-serif"}}>
                  Who We Are
                </h1>
                <hr className={`w-50 ${isVisible ? "fade-in active" : "fade-in"}`} />
              </Fade>
              <Fade left>
              <p className="lead">
                <h2 style={{fontSize:"20px"}}>Premier Measurement Solutions Private Limited</h2>
                <p style={{fontSize:"18px",  fontFamily: "var(--bs-body-font-family)",
                      fontWeight: "var(--bs-body-font-weight)",
                      lineHeight: "var(--bs-body-line-height)",
                      color: "white",
                      textAlign: "var(--bs-body-text-align)",}}>Welcome to Premier Measurement Solutions, a premier leader in the field of test and measurement solutions. Established a decade ago, we have been at the forefront of precision measurement technology, serving a diverse range of industries – from telecommunications to manufacturing and research with our unwavering commitment to quality, innovation, and customer satisfaction.</p>
                <h1 className={`display-6 mb-2 ${isVisible ? "fade-in active" : "fade-in"}`}style={{fontSize:"22px" ,fontFamily:"sans-serif"}}>
                Our mission
                </h1>
                <hr className={`w-50 ${isVisible ? "fade-in active" : "fade-in"}`} />
                
                <p style={{fontSize:"18px",marginTop:"-14px",
                    fontFamily: "var(--bs-body-font-family)",
                    fontWeight: "var(--bs-body-font-weight)",
                    lineHeight: "var(--bs-body-line-height)",
                    color: "white",
                    textAlign: "var(--bs-body-text-align)",}}>
                  At Premier Measurement Solutions, our mission is to revolutionize the way industries measure, analyze, and optimize their processes. We are dedicated to delivering state-of-the-art test and measurement solutions that empower organizations to achieve the highest standards of quality, efficiency, and reliability. Our work is driven by the belief that precision measurement is the cornerstone of progress and success in today's fast-paced world.
                  </p>
                </p>
              
              
              <NavLink
                to="./contact"
                className="btn btn-primary rounded-pill px-4 py-2"
              >
                Contact US
              </NavLink>

              </Fade>
            </div>
            
          </div>
        </div>
        <br></br>
        {/* </div> */}
      </section>
      <br></br>
    </div>
  );
};

export default About;
