import React, { useState } from 'react';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Fade, Reveal } from "react-reveal";
const videoPath = `${process.env.PUBLIC_URL}/video/video.mp4`;

    const Contact = () => {
    const [formData, setFormData] = useState({
      name: '',
      subject: '',
      mobileNumber: '',
      message: '',
      senderEmail: '', // Added senderEmail field
      country: '', // Added country field
    });
    const [cardHeight, setCardHeight] = useState('100%'); // Initial card height

    const allowedCountries = [
      'Select Country',
      'India',
      'China',
      'Taiwan',
      'Europe',
      'Africa',
      'Japan',
      'Canada',
      'Mexico',
      'United States',
      'Central America',
      'Australia',
      'Others', // Add 'Others' as an option
    ];
  
    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });

      if (name === 'country') {
        if (value === 'Others') {
          setCardHeight('102%'); // Adjust the height as needed
        } else {
          setCardHeight('100%'); // Reset to the initial height
        }
      }
    };
  
    const handleSendEmail = async () => {

      if (formData.name === '') {
        alert('Name is required.');
        return;
      }

      if (formData.subject === '') {
        alert('Subject should not be empty.');
        return;
      }

      if (formData.message === '') {
        alert('Message is required.');
        return;
      }


          // Email validation
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailPattern.test(formData.senderEmail)) {
      alert('Invalid email format.');
      return;
    }
    if (formData.country === 'Others' && formData.customCountry === '') {
      alert('Please specify the custom country name.');
      return;
    }

    // Construct the email content based on the form data
    const emailContent = `
      Name: ${formData.name}
      Subject: ${formData.subject}
      Mail: ${formData.senderEmail}
      Mobile Number: ${formData.mobileNumber}
      Country: ${formData.country === 'Others' ? formData.customCountry : formData.country}
      Message: ${formData.message}
    `;
  
      // Send the email content to your backend
      try {
        const response = await fetch('http://localhost:3001/send', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            emailContent,
            senderEmail: formData.senderEmail, // Pass the sender's email
          }),
        });
  
        if (response.status === 200) {
          alert('Email sent successfully');
                  // Clear the form inputs only when the email is sent successfully
        setFormData({
          name: '',
          subject: '',
          mobileNumber: '',
          message: '',
          senderEmail: '',
          country: '',
        });
        } else {
          alert('Failed to send email');
        }
      } catch (error) {
        console.error('Error:', error);
      }
  
      
    };
  return (
    <div>
      <section className="contact section bd-container" id="contact">
        
        <div className="contact__container bd-grid">
          <div className="contact-details">
            <div className="contact__info">
              <div className="contact-title">
              <Fade bottom>
              <h1 className={`display-6 text-center text-black mb-2 text-bold `} style={{ fontWeight: "400", color:"black",fontSize:"33px",fontFamily:"sofia-pro-soft-regular,sans-serif" }}>
              Contact us
              </h1>
              
              <hr className={`w-25 mx-auto text-black `} />
              </Fade>
              <Fade bottom delay="800">
                {/* <p className="contact__description-head" style={{fontSize:"42px"}}>Contact us</p> */}
                <p className="contact__description" style={{fontSize:"22px", color:"black",fontFamily:"sofia-pro-soft-regular,sans-serif"}}>
              </p>
              </Fade>
              </div>
            </div>
          </div>
          <Row style={{marginRight:"-10%",justifyContent:"center"}}>
          
            {/* <Col md={6}>
            <Fade left>
            <Card className="contact-card" style={{ height: cardHeight }}>
              <Card.Body style={{marginBottom:"2.5%"}}>
                <div className="form-group" style={{marginBottom:"2%"}}>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    className="form-control"
                    placeholder='Name'
                  />
                </div>
                <div className="form-group" style={{marginBottom:"2%"}}>
                  <input
                    type="text"
                    id="subject"
                    name="subject"
                    value={formData.subject}
                    onChange={handleChange}
                    className="form-control"
                    placeholder='Subject'
                  />
                </div>
                <div className="form-group" style={{marginBottom:"2%"}}>
                  <input
                    type="email"
                    id="senderEmail"
                    name="senderEmail"
                    value={formData.senderEmail}
                    onChange={handleChange}
                    className="form-control"
                    placeholder='Email'
                    pattern='/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/'
                  />
                </div>
                <div className="form-group" style={{marginBottom:"2%"}}>
                  <input
                    type="text"
                    id="mobileNumber"
                    name="mobileNumber"
                    value={formData.mobileNumber}
                    onChange={handleChange}
                    className="form-control"
                    placeholder='Mobile Number'
                    pattern='/^\+\d{1,}$/'
                  />
                </div>
                  <div className="form-group" style={{ marginBottom: "2%" }}>
                    <div className="custom-select">
                      <select
                        id="country"
                        name="country"
                        value={formData.country}
                        onChange={handleChange}
                        className="form-control"
                      >
                        {allowedCountries.map((country, index) => (
                          <option key={index} value={country}>
                            {country}
                          </option>
                        ))}
                      </select>
                      <div className="custom-arrow">▼</div>
                    </div>
                  </div>
                  {formData.country === 'Others' && (
                              <div className="form-group" style={{ marginBottom: "2%" }}>
                                <input
                                  type="text"
                                  id="customCountry"
                                  name="customCountry"
                                  value={formData.customCountry}
                                  onChange={handleChange}
                                  className="form-control"
                                  placeholder="Country Name"
                                />
                              </div>
                            )}

                <div className="form-group" style={{marginBottom:"2%"}}>
                  <textarea
                    id="message"
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    className="form-control"
                    placeholder='Message'
                  />
                </div >
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <button onClick={handleSendEmail} className="btn btn-primary" style={{ marginRight: "10px" }}>
                  Submit
                </button>
            </div>
                    </Card.Body>

                  </Card>
                  </Fade>
                </Col> */}
            
            <Col  md={6} className="cbox-inner">
            <row>
            <Col className="cont-cd" style={{width:"80%",marginBottom:"2%"}}>

              <Fade right>
              <Card className="contacts-card" style={{height:'190px'}}>
                <Card.Body>
                <p
                  className="cbox-message"
                  style={{
                    fontSize: "18px",
                    fontFamily: "var(--bs-body-font-family)",
                    fontWeight: "500",
                    lineHeight: "var(--bs-body-line-height)",
                    color: "black",
                    textAlign: "-webkit-match-parent",
                    width: "83%",
                  }}
                >
                  Premier Measurement Solutions Private Limited<br />
                  </p>
                  <p
                  className="cbox-message"
                  style={{
                    fontSize: "18px",
                    fontFamily: "var(--bs-body-font-family)",
                    fontWeight: "400",
                    lineHeight: "var(--bs-body-line-height)",
                    color: "black",
                    textAlign: "-webkit-match-parent",
                    width: "83%",
                    marginBottom: "20px", // Add margin to create space below the paragraph

                  }}
                >
                  #847, 2nd Floor, 'A' Block,
                  Sahakaranagar<br />
                  Bangalore - 560092<br />
                  <Fade bottom delay="600">
                  <p style={{marginTop:"3%"}}>
                  <img
                          src={process.env.PUBLIC_URL + "/images/telephone.png"}
                          alt="LinkedIn Icon"
                          className="contact-link"
                          
                        />
                    <span style={{ marginLeft: '5px' }}>080-4164 3659</span>        
                    <span style={{ marginLeft: '20px' }}></span>         
 
                     

                     </p>
                     <p>
                     <img
                          src={process.env.PUBLIC_URL + "/images/email.png"}
                          alt="Mail Icon"
                          className="contact-img"
                        />
                         <span style={{ marginLeft: '5px' }}>info@pmeasure.com</span>  
                     </p>
                     </Fade>
                  <br/>

                </p>

                
              </Card.Body>
              </Card>
              </Fade>
            </Col>
            </row>
            <row>
              <Col style={{width:"80%"}}>
              <Fade right>
              <Card className="contacts2-card" >
                <Card.Body>
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3886.5604959128596!2d77.586511!3d13.063623999999999!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae18223cd3b519%3A0x1e1d00b6c1ebb23f!2sPmeasure.com!5e0!3m2!1sen!2sin!4v1415326776732&q=Premier+Measurement+Solutions+Private+Limited"
                    width="600"
                    height="180"
                    frameBorder="0"
                    style={{ border: 0,width:"-webkit-fill-available" }}
                  ></iframe>
                </Card.Body>
              </Card>
              </Fade>
            </Col>
            </row>

            </Col>
          </Row>

        </div>
      </section>
    </div>
  );
            };

export default Contact;
