import React, { useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Fade, Slide } from 'react-reveal';
import { Link, useHistory, useLocation } from 'react-router-dom';

// import { Fade, Slide } from "react-reveal";

const ProductPage = () => {
  useEffect(() => {
    // Scroll to the top when the component mounts
    window.scrollTo(0, 0);
  }, []);
  const location = useLocation();

  useEffect(() => {
    document.title = 'Pmeasure';
    if (location.hash === '#cellular') {
      const cellularSection = document.getElementById('cellular');
      if (cellularSection) {
        cellularSection.scrollIntoView({ behavior: 'smooth' });
      }
    }
    if (location.hash === '#chambers') {
      const cellularSection = document.getElementById('chambers');
      if (cellularSection) {
        cellularSection.scrollIntoView({ behavior: 'smooth' });
      }
    }
    if (location.hash === '#vaunix') {
      const cellularSection = document.getElementById('vaunix');
      if (cellularSection) {
        cellularSection.scrollIntoView({ behavior: 'smooth' });
      }
    }
    
  }, [location]);

  // Define Litepoint hardware products
  const litepointHardwareProducts = [
    {
        imageSrc: `${process.env.PUBLIC_URL}/images/IQxel-MX.png`,
        productName: "IQxel-MX High Performance 802.11be Test System",
        style: { height: "110px" },
        productDescription: "For Wi-Fi 7, Wi-Fi 6E & Wi-Fi 6",
      productLink:"https://www.litepoint.com/products/iqxel-mx-high-performance-802-11be-test-system/"

      },
      
      {
        imageSrc: `${process.env.PUBLIC_URL}/images/IQxel-MW.jpg`,
        productName: "IQxel-MW 7G",
        style: { height: "110px" },

        productDescription: "Wi-Fi 6 and Wi-Fi 6E in the 6 GHz Band, with Wi-Fi 7 EHT160 MHz support",
      productLink: "https://www.litepoint.com/products/iqxel-mw-7g/",

      },
      {
        imageSrc: `${process.env.PUBLIC_URL}/images/iQFR1-RU.webp`,
        style: { height: "110px" },
        productName: "IQFR1-RU 5G FR1 O-RAN Radio Unit Test System",
        productDescription: "Simplified test solution for 5G FR1 O-RAN Radio Units.",
      productLink: "https://www.litepoint.com/products/iqfr1-ru-5g-fr1-o-ran-radio-unit-test-system/",

      },
      {
        imageSrc: `${process.env.PUBLIC_URL}/images/IQgig-UWB.jpg`,
        productName: "IQgig-UWB+™ Tester",
        style: { height: "135px" },
        productDescription: "Next gen Ultra-Wideband (UWB) Test System",
      productLink: "https://www.litepoint.com/products/iqgig-uwb-tester/",

      },

  ];

  // Define Litepoint software products
  const litepointSoftwareProducts = [
    {
        imageSrc: `${process.env.PUBLIC_URL}/images/IQxstream-5G.jpg`,
        style: { height: "97px" },
      productName: "IQxstream-5G FR1 Cellular Tester",
      productDescription: "5G FR1 Test System for 5G NR UE & Small Cell Testing",
      productLink: "https://www.litepoint.com/products/iqxstream-5g/",

    },
    {
        imageSrc: `${process.env.PUBLIC_URL}/images/IQgig-5G.jpg`,
      productName: "IQgig-5G FR2 mmWave Test System",
      productDescription: "Fully Integrated Tester for 5G NR UE & Small Cell Testing",
      productLink: "https://www.litepoint.com/products/iqgig-5g/",

    },
    {
        imageSrc: `${process.env.PUBLIC_URL}/images/5G-IQFR1.jpg`,
        style: { height: "120px" },
        productName: "IQcell-5G Signaling Test Solution",
        productDescription: "5G NR FR1 & FR2 Test Solution",
        productLink: "https://www.litepoint.com/products/iqcell-5g-signaling-test-solution/",

      },
      {
        imageSrc: `${process.env.PUBLIC_URL}/images/IQxstream-M-.jpg`,
        productName: "IQxstream-M",
        productDescription: "Multi-DUT manufacturing test solution capable of calibration and verification of 2G/3G/4G devices",
        productLink: "https://www.litepoint.com/products/iqxstream-m/",

      },
    // Add more Litepoint software products as needed
  ];

  // Define Vaunix products
  const vaunixProducts = [
    {
        imageSrc: `${process.env.PUBLIC_URL}/images/vaunix-digital.jpg`,
        style: { height: "66px" },
        productName: "Digital Attenuators",
        productDescription: "Portable and Programmable RF and Microwave Digital Step Attenuators",
        productLink: "https://vaunix.com/digital-attenuators/",

      },
      {
        imageSrc: `${process.env.PUBLIC_URL}/images/vaunix-signal-generator.jpg`,
        style: { height: "66px" },
        productName: "Signal Generators",
        productDescription: "LMS and LSG Series RF and Microwave Signal Generators ",
        productLink: "https://vaunix.com/digital-rf-signal-generators/",

      },
      {
        imageSrc: `${process.env.PUBLIC_URL}/images/vaunix-signal-hub.jpg`,
        productName: "RF Switches",
        productDescription: "USB Programmable SPDT and SP4T Solid-state RF and Microwave Switches",
        productLink: "https://vaunix.com/digital-rf-switches/",

      },
      {
        imageSrc: `${process.env.PUBLIC_URL}/images/vaunix-phase-shifter.jpg`,
        productName: "Phase Shifters",
        productDescription: "Portable and Programmable RF and Microwave Digital Phase Shifters",
        productLink: "https://vaunix.com/digital-phase-shifters/",

      },
      {
        imageSrc: `${process.env.PUBLIC_URL}/images/vaunix-signal-hub.jpg`,
        productName: "USB Hubs",
        productDescription: "Ruggedized, 4-Port, High-Powered USB Hubs for RF, Microwave, ATE and Audio Testing",
        productLink: "https://vaunix.com/rugged-usb-hubs/",

      },
      {
        imageSrc: `${process.env.PUBLIC_URL}/images/vaunix-power-divider (1).jpg`,
        productName: "Power Dividers",
        productDescription: "RF Power Dividers/Combiners Vaunix Lab Brick LPD series RF Power Dividers/Combiners are optimized for performance",
        productLink: "https://vaunix.com/lpd-series-rf-power-dividers-combiners/",

      },
      {
        imageSrc: `${process.env.PUBLIC_URL}/images/handover11.jpg`,
        style: { height: "98px" },
        productName: "Handover Test Systems",
        productDescription: "Offers a range of attenuator matrices for unique wireless handover testing requirements.",
        productLink: "https://vaunix.com/handover-test-systems/",

      },
    //   {
    //     imageSrc: `${process.env.PUBLIC_URL}/images/IQgig-5Gb.webp`,
    //     productName: "Vaunix Product 8",
    //     productDescription: "Description of Vaunix product 8 goes here.",
    //   },
  ];

  const spirentInstruments = [
    {
      imageSrc: `${process.env.PUBLIC_URL}/images/pal7.jpg`,
    productName: "Pal-7",
    style: { height: "150px" },
    productLinkTarget: "_self", // Add this line
    productDescription: " Wi-Fi 7 traffic endpoints,virtual stations and synchroSniffer probes  ",
    productLink: `${process.env.PUBLIC_URL}/assets/DS OCTOBOX Pal-7_RevB.pdf`

  },
    {
        imageSrc: `${process.env.PUBLIC_URL}/images/pal6.jpg`,
      productName: "Pal-6E",
      style: { height: "150px" },
      productLinkTarget: "_self", // Add this line
      productDescription: " Wi-Fi 6E traffic endpoints,virtual stations and synchroSniffer probes ",
      productLink: `${process.env.PUBLIC_URL}/assets/octoScope_Pal_datasheet.pdf`

    },
    {
        imageSrc: `${process.env.PUBLIC_URL}/images/image (4).png`,
      productName: "STApal",
      style: { height: "150px" },
      productLinkTarget: "_self", // Add this line

      productDescription: "Wi-Fi 6E traffic endpoints and  synchro-sniffer probes",
      productLink: `${process.env.PUBLIC_URL}/assets/octoScope_Pal_datasheet.pdf`

    },
    {
        imageSrc: `${process.env.PUBLIC_URL}/images/image (5).png`,
      productName: "Multipath Emulator (MPE)",
      style: { height: "150px" },
      productLinkTarget: "_self", // Add this line

      productDescription: "Broadband (DC – 7.5 GHz) multipath emulator",
      
      productLink: `${process.env.PUBLIC_URL}/assets/Datasheet OCTOBOX OB-MPE2.pdf`

    },
    
    {
        imageSrc: `${process.env.PUBLIC_URL}/images/octoBox_iGen_front_view.png`,
      productName: "iGen Interference Generator",
      style: { height: "160px" },

      productLinkTarget: "_self", // Add this line

      productDescription: "Creates realistic interference conditions ",
      productLink: `${process.env.PUBLIC_URL}/assets/Datasheet OCTOBOX iGen.pdf`

    },

    {
        imageSrc: `${process.env.PUBLIC_URL}/images/quadatten.jpeg`,
      productName: "quadAtten",
      style: { height: "170px" },

      productLinkTarget: "_self", // Add this line

      productDescription: "4 programmable RF attenuators controlled by USB or Ethernet",
      productLink: `${process.env.PUBLIC_URL}/assets/Datasheet OCTOBOX quadAtten.pdf`

    },

    {
        imageSrc: `${process.env.PUBLIC_URL}/images/mimospiltter.jpeg`,
      productName: "4x4 MIMO Splitter",
      style: { height: "170px" },
      productLinkTarget: "_self", // Add this line

      productDescription: "4x4 MIMO 1:2 and 1:4 RF splitter",
      productLink: `${process.env.PUBLIC_URL}/assets/Datasheet OCTOBOX 4x4 MIMO.pdf`

    },
    {
      imageSrc: `${process.env.PUBLIC_URL}/images/quadswitch.jpeg`,
    productName: "1:2 Switch",
    style: { height: "170px" },
    productLinkTarget: "_self", // Add this line

    productDescription: "Dual single-pole single throw  RF switch module",
    productLink: `${process.env.PUBLIC_URL}/assets/Datasheet OCTOBOX quadSwitch.pdf`

  },
  ];
  
  const spirentChambers = [

    {
      imageSrc: `${process.env.PUBLIC_URL}/images/box.png`,
    productName: "palBox & smartBox",
    productDescription: "OctoBox chamber with  built-in instruments",
    productLinkTarget: "_self", // Add this line
    style: { height: "190px" },

    productLink: `${process.env.PUBLIC_URL}/assets/octoScope_Pal_datasheet.pdf`

  },

    {
      imageSrc: `${process.env.PUBLIC_URL}/images/box-38.jpeg`,
      productName: "OctoBox Testbed",
      style: { height: "190px" },
      productDescription: "Semi-anechoic chamber ( 18 & 38 inches wide)",
       productLinkTarget: "_self", // Add this line
      productLink: `${process.env.PUBLIC_URL}/assets/Datsheet_OCTOBOX Small Semi-Anechoic Chamber.pdf`

    },
    {
        imageSrc: `${process.env.PUBLIC_URL}/images/image (11).png`,
      productName: "Octobox Turntable",
      style: { height: "190px" },

      productDescription: "A built-in programmable turntable ",
      productLinkTarget: "_self", // Add this line
      productLink: `${process.env.PUBLIC_URL}/assets/Datasheet OCTOBOX Turntable.pdf`

    },
  ];
  

  return (
    <div>
      {/* Litepoint Section */}
      <section id="litepoint" style={{marginTop:"-41px"}}>
        <div className="container my-5 py-5">
        <Fade bottom>
          {/* <h3 className="fs-5 text-center mb-0" style={{ marginTop: "-74px" }}>Our Products</h3> */}
          </Fade>
        <Fade bottom>
            <h1 className="display-6 text-center mb-4">
            <p style={{ color:"Black",fontSize:"33px",fontFamily:"sofia-pro-soft-regular,sans-serif" }}>Featured Products</p>
            </h1>
            <hr className={`w-25 mx-auto text-black `} style={{marginTop:"-12px"}} />

          </Fade>
          <Fade bottom>
          <div className="d-flex flex-wrap justify-content-left" style={{justifyContent:"center"}}>
            <img
                          src={`${process.env.PUBLIC_URL}/images/litepoint-teradyne-logo.png`}
                          className="rounded-square-prod img-margin"
                          alt="..."
                        />

          
        </div>   
          </Fade>

          {/* connectivity Products */}
          <div id="connectivity" className="product-section">
          <div className="row">
            <Slide left>
              <h4 className="mb-3">Connectivity:</h4>
              {litepointHardwareProducts.map((product, index) => (
                <div key={index} className="col-md-3 mb-4">
                  <div className="card" style={{ height: "250px" }}>
                  <a
                    href={product.productLink}  // Use the productLink for the URL
                    target="_blank"
                    rel="noopener noreferrer"
                    className="card-link"
                  >
                    <img
                      src={product.imageSrc}
                      alt={product.productName}
                      style={product.style} // Apply style directly to the image

                      
                      className="card-img-top"
                    />
                    <div className="card-body">
                      <h5 className="card-title">{product.productName}</h5>
                      <p className="card-text">{product.productDescription}</p>
                    </div>
                    </a>
                  </div>
                </div>
              ))}
            </Slide>
          </div>
          </div>

          <div id="cellular" className="product-section">
            {/* Cellullar Products */}

            <div className="row">

              <Slide right>
              <h4 className="mb-3">Cellullar:</h4>
              {litepointSoftwareProducts.map((product, index) => (
                <div key={index} className="col-md-3 mb-4">
                  <div className="card" style={{ height: "250px" }}>
                                <a
                      href={product.productLink}  // Use the productLink for the URL
                      target="_blank"
                      rel="noopener noreferrer"
                      className="card-link"
                    >
                    <img
                      src={product.imageSrc}
                      alt={product.productName}
                      style={product.style} // Apply style directly to the image
                      className="card-img-top"
                    />
                    <div className="card-body">
                      <h5 className="card-title">{product.productName}</h5>
                      <p className="card-text">{product.productDescription}</p>
                    </div>
                    </a>
                  </div>
                </div>
              ))}
            </Slide>
            </div>
          </div>
        </div>
      </section>

      {/* Spirent Section */}
    <section id="spirent">
      <div className="container my-5 py-5">
        <Fade bottom>
        <div className="d-flex flex-wrap justify-content-left" style={{justifyContent:"center"}}>
        <img
          src={`${process.env.PUBLIC_URL}/images/Logo-Spirent-Blue.jpg`}
          className="rounded-square-prod img-margin"
          alt="..."
        />
    </div>
      {/* <h2 className="text-center mb-4" style={{color:"Blue"}}>Spirent</h2> */}
    </Fade>

    {/* Instruments */}
    <Row>
      <Col md={6} className="text-left">
        <Slide left>
          <h3 className="mb-4">Instruments:</h3>
        </Slide>
      </Col>
    </Row>
    <div className="row">
      {spirentInstruments.map((product, index) => (
        <div key={index} className="col-md-3 mb-4">
          <Slide left>
            <div className="card"style={{ height: "300px" }}>
            <a
        href={product.productLink}  // Use the productLink for the URL
        target={product.productLinkTarget}
        rel="noopener noreferrer"
        className="card-link"
      >
              <img
                src={product.imageSrc}
                alt={product.productName}
                style={product.style} // Apply style directly to the image

                className="card-img-top"
              />
              <div className="card-body">
                <h5 className="card-title">{product.productName}</h5>
                <p className="card-text">{product.productDescription}</p>
              </div>
              </a>
            </div>
          </Slide>
        </div>
      ))}
    </div>

    {/* Chambers */}
    <div id="chambers" className="product-section">

    <Row>
      
      <Col md={6} className="text-left">
        <Slide left>
          <h3 className="mb-4">Chambers:</h3>
        </Slide>
      </Col>
    </Row>

    <div className="row">
      {spirentChambers.map((product, index) => (
        <div key={index} className="col-md-3 mb-4">
          <Slide left>
            <div className="card" style={{ height: "300px" }}>
            <a
        href={product.productLink}  // Use the productLink for the URL
        target={product.productLinkTarget}
        rel="noopener noreferrer"
        className="card-link"
      >
              <img
                src={product.imageSrc}
                alt={product.productName}
                style={product.style} // Apply style directly to the image

                className="card-img-top"
              />
              <div className="card-body">
                <h5 className="card-title">{product.productName}</h5>
                <p className="card-text">{product.productDescription}</p>
              </div>
              </a>
            </div>
          </Slide>
        </div>
      ))}
    </div>
    </div>
  </div>
</section>

      {/* Vaunix Section */}
      <section id="vaunix">
        <div className="container my-5 py-5">
          <Fade bottom>
          <div className="d-flex flex-wrap justify-content-left" style={{justifyContent:"center"}}>
          <img
                      src={`${process.env.PUBLIC_URL}/images/logo.png`}
                      className="rounded-square-prod img-margin"
                      alt="..."
                   style={{marginBottom:"20px"}} />

          </div>

          </Fade>

          <div className="row">
            <Slide left>
              {vaunixProducts.map((product, index) => (
                <div key={index} className="col-md-3 mb-4">
                  <div className="card"style={{ height: "220px" }}>
                  <a
        href={product.productLink}  // Use the productLink for the URL
        target="_blank"
        rel="noopener noreferrer"
        className="card-link"
      >
                    <img
                      src={product.imageSrc}
                      alt={product.productName}
                      style={product.style} // Apply style directly to the image

                      className="card-img-top"
                    />
                    <div className="card-body">
                      <h5 className="card-title">{product.productName}</h5>
                      <p className="card-text">{product.productDescription}</p>
                    </div>
                    </a>
                  </div>
                </div>
              ))}
            </Slide>
          </div>
        </div>
      </section>


    </div>
  );
};

export default ProductPage;


