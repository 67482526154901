import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Fade } from "react-reveal";

import "../services.css"; // Import your CSS file


const WhatSetsUsApart = () => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
      const handleScroll = () => {
        const element = document.getElementById("detail");
        if (!isVisible && element.getBoundingClientRect().top < window.innerHeight) {
          setIsVisible(true);
        }
      };
  
      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }, [isVisible]);
  return (
    <div>
      <section id="what-sets-us-apart" style={{marginBottom:"30px"}}>
        <Container>
        <Row className="mb-5">
            <Col>
              <h2 className="text-center"  style={{ color:"Black",fontSize:"33px",fontFamily:"sofia-pro-soft-regular,sans-serif" }}>What Sets Us Apart</h2>
              <hr className="w-25 mx-auto" />
            </Col>
          </Row>

 
          <Row style={{marginTop:"30px"}}>
  <Col md={6}>
    <Fade left>
      <div className="card">
        <img
          src={process.env.PUBLIC_URL + "/images/technology-7111799_1920.jpg"}
          alt="Card 1"
          className="card-image"
        />
        <p style={{  fontSize: "22px", fontFamily: "var(--bs-body-font-family)",textAlign:"center",fontWeight:"500" }}>Unparalleled Expertise</p>

        <div className="card-text-wrapper">
          <h6 className="font-services" style={{ overflowY: 'auto'}}>
          <p className="card-text lead" style={{
  fontSize: "var(--bs-body-font-size)",
  fontFamily: "var(--bs-body-font-family)",
  fontWeight: "var(--bs-body-font-weight)",
  lineHeight: "var(--bs-body-line-height)",
  color: "var(--bs-body-color)",
  textAlign: "var(--bs-body-text-align)",
  
}}>
            With decades of collective experience in wireless communication technologies, our team is equipped to tackle the most complex measurement challenges. We are committed to deliver tailored solutions that address specific needs.</p>
          </h6>
        </div>
      </div>
    </Fade>
  </Col>

  <Col md={6}>
    <Fade right>
      <div className="card">
        <img
          src={process.env.PUBLIC_URL + "/images/businessman-holding-digital-tablet-success-cityscape-generated-by-ai.jpg"}
          alt="Card 2"
          className="card-image"
        />
                    <p style={{  fontSize:"22px", fontFamily: "var(--bs-body-font-family)",textAlign:"center",fontWeight:"500" }}>Innovation at the Core</p>

        <div className="card-text-wrapper">
          <h6 className="font-services" style={{ overflowY: 'auto'}}>
          <p className="card-text lead" style={{  fontSize: "var(--bs-body-font-size)",
  fontFamily: "var(--bs-body-font-family)",
  fontWeight: "var(--bs-body-font-weight)",
  lineHeight: "var(--bs-body-line-height)",
  color: "var(--bs-body-color)",
  textAlign: "var(--bs-body-text-align)", }}>

We're committed to continuous innovation, staying at the forefront of technology in testing and measurement.  We embrace latest methodologies and technologies including 5G-FR1, 5G-FR2, and 802.11be to provide cutting-edge solutions..</p>
          </h6>
        </div>
      </div>
    </Fade>
  </Col>
</Row>

<Row style={{marginTop:"10px"}}>
  <Col md={6}>
    <Fade left>
      <div className="card">
        <img
          src={process.env.PUBLIC_URL + "/images/human-resources-concept (1).jpg"}
          alt="Card 3"
          className="card-image"
        />
                    <p style={{  fontSize: "22px", fontFamily:"var(--bs-body-font-family)",textAlign:"center",fontWeight:"500" }}>Customer-Centric Excellence</p>

        <div className="card-text-wrapper">
          <h6 className="font-services"style={{ overflowY: 'auto'}}>
          <p className="card-text lead" style={{ fontSize: "var(--bs-body-font-size)",
  fontFamily: "var(--bs-body-font-family)",
  fontWeight: "var(--bs-body-font-weight)",
  lineHeight: "var(--bs-body-line-height)",
  color: "var(--bs-body-color)",
  textAlign: "var(--bs-body-text-align)", }}>

            Your satisfaction is our top priority. Our customer-centric approach is embedded in every aspect of our business. We offer responsive customer support, personalized services, and a collaborative partnership that extends beyond the transaction.
          </p>
          </h6>
        </div>
      </div>
    </Fade>
  </Col>

  <Col md={6}>
    <Fade right>
      <div className="card">
        <img
          src={process.env.PUBLIC_URL + "/images/QA2.jpg"}
          alt="Card 4"
          className="card-image"
        />
                    <p style={{  fontSize: "22px", fontFamily: "var(--bs-body-font-family)",textAlign:"center",fontWeight:"500" }}>Quality Assurance</p>

        <div className="card-text-wrapper">
          <h6 className="font-services"style={{ overflowY: 'auto'}}>
          <p className="card-text lead" style={{   fontSize: "var(--bs-body-font-size)",
  fontFamily: "var(--bs-body-font-family)",
  fontWeight: "var(--bs-body-font-weight)",
  lineHeight: "var(--bs-body-line-height)",
  color: "var(--bs-body-color)",
  textAlign: "var(--bs-body-text-align)", }}>

            Precision is not negotiable. Our quality assurance processes are rigorous and uncompromising. We adhere to international standards and best practices to guarantee the accuracy, reliability, and consistency of our products and services.
         </p>
          </h6>
        </div>
      </div>
    </Fade>
  </Col>
</Row>
        </Container>
      </section>
    </div>
  );
};

export default WhatSetsUsApart;

